import { useState, useRef, useEffect } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import img from "./images/logo2.svg";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { FaSortDown } from "react-icons/fa";
import { Dialog } from "@headlessui/react";
export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate =useNavigate();


  const handleNavigation = (e) => {
    const id = e.target.id;

    switch (id) {
      case "ecommerce":
        navigate('/ecommerce')
        break;
      case "mobileapp":
        navigate('/mobile-app')
        break;
      case "uiux":
        navigate('/ui-ux')
        break;
      case "digitalmarketing":
        navigate('/digital-marketing' )
        break;
      case "gpssupplier":
        navigate('/gpssupplier')
        break;
      case "taxiservices":
        navigate('/taxi-services')
        break;
      default :  
      

      
    }
  };

  //Menu bar Dropdown
  const [isCompanyOpen, setIsCompanyOpen] = useState(false);
  const [isTechnologyOpen, setIsTechnologyOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isIndustriesOpen, setIsIndustriesOpen] = useState(false);
  const [isSolutionOpen, setIsSolutionOpen] = useState(false);
  const technologyRef = useRef(null);
  const servicesRef = useRef(null);
  const industriesRef = useRef(null);
  const solutionRef = useRef(null);
  const companyRef = useRef(null);

  

  const handleToggleCompany = () => {
    setIsCompanyOpen(!isCompanyOpen);
    setIsSolutionOpen(false);
    setIsIndustriesOpen(false);
    setIsTechnologyOpen(false);
    setIsServicesOpen(false);
  };

  const handleToggleTechnology = () => {
    setIsTechnologyOpen(!isTechnologyOpen);
    setIsServicesOpen(false);
    setIsIndustriesOpen(false);
    setIsSolutionOpen(false);
  };

  const handleToggleServices = () => {
    setIsServicesOpen(!isServicesOpen);
    setIsTechnologyOpen(false);
    setIsIndustriesOpen(false);
    setIsSolutionOpen(false);
  };

  const handleToggleIndustries = () => {
    setIsIndustriesOpen(!isIndustriesOpen);
    setIsTechnologyOpen(false);
    setIsServicesOpen(false);
    setIsSolutionOpen(false);
  };
  const handleToggleSolution = () => {
    setIsSolutionOpen(!isSolutionOpen);
    setIsIndustriesOpen(false);
    setIsTechnologyOpen(false);
    setIsServicesOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
      technologyRef.current &&
      !technologyRef.current.contains(event.target)
    ) {
      setIsTechnologyOpen(false);
    }
    if (servicesRef.current && !servicesRef.current.contains(event.target)) {
      setIsServicesOpen(false);
    }
    if (
      industriesRef.current &&
      !industriesRef.current.contains(event.target)
    ) {
      setIsIndustriesOpen(false);
    }
    if (solutionRef.current && !solutionRef.current.contains(event.target)) {
      setIsSolutionOpen(false);
    }
    if (companyRef.current && !companyRef.current.contains(event.target)) {
      setIsCompanyOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  //end
  return (
    <>
      <header className="sticky top-0 bg-white z-50 shadow-md ">
        {" "}
        {/* Make header sticky */}
        <nav
          aria-label="Global"
          className="mx-auto flex max-w-7xl items-center justify-between px-4 lg:px-8 "
        >
          <div className="flex lg:flex-1 ">
            <a href="./Home" className="-m-1.5 p-1.5">
              <img alt="Logo" src={img} className=" h-20 rounded-full" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            <ul className="flex p-0  space-x-4">
              <li className="px-3 ">
                <a
                  href="./Home"
                  className="text-[17px] font-semibold leading-6 text-gray-900 cursor-pointer"
                >
                  Home
                </a>
              </li>
              <li className="relative group px-3" ref={companyRef}>
                <a
                  onClick={handleToggleCompany}
                  className="text-[17px] font-semibold leading-6 text-gray-900 cursor-pointer"
                >
                  Company{" "}
                  <span className="inline-block ml-1">
                    <FaSortDown />
                  </span>
                </a>
                <ul
                  className={`absolute left-0 mt-2 w-48 p-0 bg-white shadow-lg rounded-lg transform transition-all duration-300 ${
                    isCompanyOpen
                      ? "opacity-100 scale-100"
                      : "opacity-0 scale-95"
                  }`}
                >
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    <a href="" className="cursor-pointer text-black ">
                      About Us
                    </a>
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    <a href="" className="cursor-pointer text-black ">
                      Client
                    </a>
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    <a href="" className="cursor-pointer text-black ">
                      Social Media
                    </a>
                  </li>
                </ul>
              </li>
              <li className="relative group px-3" ref={technologyRef}>
                <a
                  onClick={handleToggleTechnology}
                  className="text-[17px] font-semibold leading-6 text-black cursor-pointer "
                >
                  Technology{" "}
                  <span className="inline-block ml-1">
                    <FaSortDown />
                  </span>
                </a>
                <ul
                  className={`absolute left-0 mt-2 w-48 p-0 bg-white shadow-lg rounded-lg transform transition-all duration-300 ${
                    isTechnologyOpen
                      ? "opacity-100 scale-100"
                      : "opacity-0 scale-95"
                  }`}
                >
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    JavaScript
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    HTML/CSS
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    Tailwind CSS
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    React
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    Node.js
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    Express.js
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    Database
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    SQL
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    MongoDB
                  </li>
                </ul>
              </li>
              <li className="relative group px-3" ref={servicesRef}>
                <a
                  onClick={handleToggleServices}
                  className="text-[17px] font-semibold leading-6 text-gray-900 cursor-pointer"
                >
                  Services{" "}
                  <span className="inline-block ml-1">
                    <FaSortDown />
                  </span>
                </a>
                <ul
                  className={`absolute left-0 mt-2 w-48 p-0 bg-white shadow-lg rounded-lg transform transition-all duration-300 ${
                    isServicesOpen
                      ? "opacity-100 scale-100"
                      : "opacity-0 scale-95"
                  }`}
                >
                  {/* <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    Ecommerce
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    MobileApp
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    UI/Ux
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    Digital marketing
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    Gps Supplier
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    Taxi services
                  </li> */}
                  <li
                    className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2"
                    onClick={handleNavigation}
                    id="ecommerce"
                  >
                    Ecommerce
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2"
                    onClick={handleNavigation}
                    id="mobileapp"
                  >
                    MobileApp
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2"
                    onClick={handleNavigation}
                    id="uiux"
                  >
                    UI/Ux
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2"
                    onClick={handleNavigation}
                    id="digitalmarketing"
                  >
                    Digital marketing
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2"
                    onClick={handleNavigation}
                    id="gpssupplier"
                  >
                    Gps Supplier
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2"
                    onClick={handleNavigation}
                    id="taxiservices"
                  >
                    Taxi services
                  </li>
                </ul>
              </li>
              <li className="relative group px-3" ref={industriesRef}>
                <a
                  onClick={handleToggleIndustries}
                  className="text-[17px] font-semibold leading-6 text-gray-900 cursor-pointer"
                >
                  Industries{" "}
                  <span className="inline-block ml-1">
                    <FaSortDown />
                  </span>
                </a>
                <ul
                  className={`absolute left-0 mt-2 w-48 p-0 bg-white shadow-lg rounded-lg transform transition-all duration-300 ${
                    isIndustriesOpen
                      ? "opacity-100 scale-100"
                      : "opacity-0 scale-95"
                  }`}
                >
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    Retail
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    Technology
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    Transportation
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    Manufectures
                  </li>
                </ul>
              </li>
              <li className="relative group px-3" ref={solutionRef}>
                <a
                  onClick={handleToggleSolution}
                  className="text-[17px] font-semibold leading-6 text-gray-900 cursor-pointer"
                >
                  Solutions{" "}
                  <span className="inline-block ml-1">
                    <FaSortDown />
                  </span>
                </a>
                <ul
                  className={`absolute left-0 mt-2 w-48 p-0 bg-white shadow-lg rounded-lg transform transition-all duration-300 ${
                    isSolutionOpen
                      ? "opacity-100 scale-100"
                      : "opacity-0 scale-95"
                  }`}
                >
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    E-commerce Solutions
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    Mobille App
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500 m-2">
                    Web and CMS
                  </li>
                </ul>
              </li>

              <li className="px-3">
                <a
                  href="/contact"
                  className="text-[17px] font-semibold leading-6 text-gray-900"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <span>
              <a
                href="/SignIn"
                className="text-sm font-bold leading-6 text-blue-600 border-2 border-sky-900 px-3 py-2 rounded-full"
              >
                SignIn
              </a>
            </span>
          </div>
        </nav>
        <Dialog
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          className="lg:hidden"
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <img alt="Logo" src={img} className="h-8 w-auto" />
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <a
                    href="./Home"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Home
                  </a>

                  {/* Company Dropdown */}
                  <div className="relative">
                    <a
                      onClick={handleToggleCompany}
                      className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer block"
                    >
                      Company{" "}
                      <span className="inline-block ml-1">
                        <FaSortDown />
                      </span>
                    </a>
                    <ul
                      className={`relative mt-2 w-full bg-white shadow-lg rounded-lg transition-all duration-300 ${
                        isCompanyOpen
                          ? "opacity-100"
                          : "opacity-0 h-0 overflow-hidden"
                      }`}
                    >
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        About Us
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Client
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Social Media
                      </li>
                    </ul>
                  </div>

                  {/* Technology Dropdown */}
                  <div className="relative">
                    <a
                      onClick={handleToggleTechnology}
                      className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer"
                    >
                      Technology{" "}
                      <span className="inline-block ml-1">
                        <FaSortDown />
                      </span>
                    </a>
                    <ul
                      className={`relative mt-2 w-full bg-white shadow-lg rounded-lg transition-all duration-300 ${
                        isTechnologyOpen
                          ? "opacity-100"
                          : "opacity-0 h-0 overflow-hidden"
                      }`}
                    >
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        JavaScript
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        HTML/CSS
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Tailwind CSS
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        React
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Node.js
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Express.js
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Database
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        SQL
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        MongoDB
                      </li>
                    </ul>
                  </div>

                  {/* Services Dropdown */}
                  <div className="relative">
                    <a
                      onClick={handleToggleServices}
                      className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer"
                    >
                      Services{" "}
                      <span className="inline-block ml-1">
                        <FaSortDown />
                      </span>
                    </a>
                    <ul
                      className={`relative mt-2 w-full bg-white shadow-lg rounded-lg transition-all duration-300 ${
                        isServicesOpen
                          ? "opacity-100"
                          : "opacity-0 h-0 overflow-hidden"
                      }`}
                    >
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Ecommerce
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        MobileApp
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        UI/Ux
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Digital Marketing
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Gps Supplier
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Taxi services
                      </li>
                    </ul>
                  </div>
                  {/* Industries  Dropdown*/}
                  <div className="relative">
                    <a
                      onClick={handleToggleIndustries}
                      className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer"
                    >
                      Industries{" "}
                      <span className="inline-block ml-1">
                        <FaSortDown />
                      </span>
                    </a>
                    <ul
                      className={`relative mt-2 w-full bg-white shadow-lg rounded-lg transition-all duration-300 ${
                        isIndustriesOpen
                          ? "opacity-100"
                          : "opacity-0 h-0 overflow-hidden"
                      }`}
                    >
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Retail
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Technology
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Transportation
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Manufectures
                      </li>
                    </ul>
                  </div>
                  {/* Solutions */}
                  <div className="relative">
                    <a
                      onClick={handleToggleSolution}
                      className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer"
                    >
                      Solutions{" "}
                      <span className="inline-block ml-1">
                        <FaSortDown />
                      </span>
                    </a>
                    <ul
                      className={`relative mt-2 w-full bg-white shadow-lg rounded-lg transition-all duration-300 ${
                        isSolutionOpen
                          ? "opacity-100"
                          : "opacity-0 h-0 overflow-hidden"
                      }`}
                    >
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Ecommerce
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        MobileApp
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-200 hover:border-b-2 hover:border-orange-500">
                        Web and CMS
                      </li>
                    </ul>
                  </div>
                  <a
                    href="./Contact"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Contact Us
                  </a>
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </>
  );
}
