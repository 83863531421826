import { useState } from "react";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/react";
import { Field, Label, Switch } from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import img from "./images/logo.png";
import banner from "./images/banner.jpg";
import serv1 from "./images/gps.png";
import serv2 from "./images/alert.jpg";
import serv3 from "./images/Backup.jpg";
import serv4 from "./images/facility.jpeg";
import about from "./images/aboutus.png";
import contact from "./images/contact.jpg";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import {
  FaArrowRight,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaMailBulk,
  FaMailchimp,
  FaTwitter,
  FaVoicemail,
  FaYoutube,
} from "react-icons/fa";
import {
  FaLocationDot,
  FaMapLocation,
  FaPhoenixSquadron,
  FaPhone,
} from "react-icons/fa6";

// change
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {} from "@headlessui/react";
// changeend

const products = [
  {
    name: "Analytics",
    description: "Get a better understanding of your traffic",
    href: "#",
    icon: ChartPieIcon,
  },
  {
    name: "Engagement",
    description: "Speak directly to your customers",
    href: "#",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Security",
    description: "Your customers’ data will be safe and secure",
    href: "#",
    icon: FingerPrintIcon,
  },
  {
    name: "Integrations",
    description: "Connect with third-party tools",
    href: "#",
    icon: SquaresPlusIcon,
  },
  {
    name: "Automations",
    description: "Build strategic funnels that will convert",
    href: "#",
    icon: ArrowPathIcon,
  },
];
const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "#", icon: PhoneIcon },
];

export default function Contact() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const validationSchema = Yup.object().shape({
    // firstName: Yup.string().required('First name is required'),
    firstName: Yup.string()
      .required("First name is required")
      .matches(/^[A-Za-z]+$/, "Only alphabets are allowed"),
    // lastName: Yup.string().required('Last name is required'),

    lastName: Yup.string()
      .required("last name is required")
      .matches(/^[A-Za-z]+$/, "Only alphabets are allowed"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, "Phone number is not valid")
      .required("Phone number is required"),
    Address: Yup.string()
      .required("Address is required")
      .matches(/^[A-Za-z]+$/, "Only alphabets are allowed"),

    agreed: Yup.boolean().oneOf([true], "You must accept the privacy policy"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // Form submit handler
  const onSubmit = (data) => {
    console.log("Form Submitted:", data);
  };
  return (
    <>
      <header className="sticky top-0 bg-white z-50 shadow-md">
        {" "}
        {/* Make header sticky */}
        <nav
          aria-label="Global"
          className="mx-auto flex max-w-7xl items-center justify-between px-4 lg:px-8"
        >
          <div className="flex lg:flex-1">
            <a href="./Home" className="-m-1.5 p-1.5">
              <img alt="Logo" src={img} className="h-20 w-auto" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            <a
              href="./Home"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Home
            </a>
            <a
              href="./About"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              About Us
            </a>
            <a
              href="./Services"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Services
            </a>
            <a
              href="./gallery"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Gallery
            </a>
            <a
              href="./contact"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Contact Us
            </a>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <span>
              <a
                href="./SignIn"
                className="text-sm font-bold leading-6 text-blue-600 border-2 border-sky-900 px-3 py-2 rounded-full"
              >
                SignIn
              </a>
              <button
                href="SignUp"
                className="text-sm font-bold leading-6 text-blue-600 ml-2 border px-3 py-2 bg-sky-900  rounded-full"
              >
                <a href="./signup" className="text-white">
                  SignUp
                </a>
              </button>
            </span>
          </div>
        </nav>
        <Dialog
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
          className="lg:hidden"
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <img alt="Logo" src={img} className="h-8 w-auto" />
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <a
                    href="./Home"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Home
                  </a>
                  <a
                    href="./About"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    About Us
                  </a>
                  <a
                    href="./Services"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Services
                  </a>
                  <a
                    href="./Gallery"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Gallery
                  </a>
                  <a
                    href="./contact"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Contact Us
                  </a>
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      {/* Banner Section */}
      <div
        style={{
          backgroundImage: `url('${contact}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundBlendMode: "multiply",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust color and opacity as needed
        }}
        className="relative isolate h-56 md:h-[25rem] flex items-center justify-center overflow-hidden bg-cover bg-center w-full"
      >
        <div className="absolute inset-0 flex flex-col items-center text-center px-4 md:px-8 space-y-4 mt-24">
          <h1 className="text-3xl md:text-6xl font-mono font-bold text-white">
            Contact Us
          </h1>
          <p className="text-2xl md:text-xl text-white max-w-2xl font-bold">
            <a href="./home" className="text-white">
              Home
            </a>
          </p>
        </div>
      </div>

      {/* Contact Us Part */}
      <div className="flex flex-col md:flex-row h-auto p-4 md:space-y-0 space-y-8 justify-center">
        {/* Get In Touch Section */}
        <div className="w-full md:w-2/5 h-auto p-2 pt-10 md:mr-2 justify-between">
          <div className="pb-6">
            <h2 className="font-bold text-2xl md:text-4xl tracking-wide">
              Get In Touch
            </h2>
            <p className="pt-8 text-base md:text-xl leading-6">
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content. Lorem ipsum may
              be used as a placeholder before the final copy is available.
            </p>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="inline-flex space-x-2 items-center p-6 w-full md:w-96 bg-sky-900 rounded-md shadow-lg shadow-blue-500/50">
              <FaPhone className="text-2xl w-10 text-white" />
              <span className="font-bold w-80 text-white">+91 6268916111</span>
            </div>
            <div className="inline-flex space-x-2 items-center p-6 w-full md:w-96 bg-sky-900 rounded-md shadow-lg shadow-blue-500/50">
              <FaEnvelope className="text-3xl w-10 text-white" />
              <span className="font-bold w-80 text-white">
                expgpsrpr@gmail.com
              </span>
            </div>
            <div className="inline-flex space-x-2 items-center p-6 w-full md:w-96 bg-sky-900 rounded-md shadow-lg shadow-blue-500/50">
              <FaLocationDot className="text-3xl w-10 text-white" />
              <span className="font-bold w-80 text-white">
                78, Gautam Nagar St, Bapuji Nagar, Bhubaneswar, Odisha 751014
              </span>
            </div>
          </div>
        </div>

        {/* Contact Us Form Section */}
        <div className="w-full md:w-2/5 p-2 md:ml-2 pt-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-12">
              <div>
                <h1 className="leading-7 text-gray-900 text-2xl md:text-4xl font-bold">
                  Contact Us
                </h1>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      First name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="first-name"
                        {...register("firstName", {
                          required: "First name is required",
                          pattern: {
                            value: /^[A-Za-z]+$/, // Regex pattern for alphabetic characters only
                            message: "First name should only contain letters",
                          },
                        })}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.firstName && (
                        <p className="text-red-500 text-sm">
                          {errors.firstName.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Last name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="last-name"
                        {...register("lastName", {
                          required: "Last name is required",
                          pattern: {
                            value: /^[A-Za-z]+$/, // Regex pattern for alphabetic characters only
                            message: "Last name should only contain letters",
                          },
                        })}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.lastName && (
                        <p className="text-red-500 text-sm">
                          {errors.lastName.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-full">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        {...register("email")}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.email && (
                        <p className="text-red-500 text-sm">
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-full">
                    <label
                      htmlFor="phone-number"
                      className="block text-sm font-semibold leading-6 text-gray-900"
                    >
                      Phone number
                    </label>
                    <div className="relative mt-2.5">
                      <input
                        type="phone"
                        id="phone-number"
                        {...register("phoneNumber")}
                        className="block w-full rounded-md border-0 px-4 py-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.phoneNumber && (
                        <p className="text-red-500 text-sm">
                          {errors.phoneNumber.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="Address"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Address
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="Address"
                        {...register("Address")}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.Address && (
                        <p className="text-red-500 text-sm">
                          {errors.Address.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-b border-gray-900/10 pb-12">
                <div className="flex gap-x-4 sm:col-span-2">
                  <div className="flex h-6 items-center">
                    <Controller
                      name="agreed"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Switch
                          checked={field.value}
                          onChange={field.onChange}
                          className="group flex w-8 flex-none cursor-pointer rounded-full bg-gray-200 p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 data-[checked]:bg-indigo-600"
                        >
                          <span
                            aria-hidden="true"
                            className="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out group-data-[checked]:translate-x-3.5"
                          />
                        </Switch>
                      )}
                    />
                  </div>
                  <label className="text-sm leading-6 text-gray-600">
                    By selecting this, you agree to our{" "}
                    <a href="#" className="font-semibold text-indigo-600">
                      privacy&nbsp;policy
                    </a>
                    .
                  </label>
                  {errors.agreed && (
                    <p className="text-red-500 text-sm">
                      {errors.agreed.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                  type="reset"
                  onClick={() => reset()}
                  className="text-md font-semibold leading-6  bg-red-600 text-white rounded-md px-3 py-2 w-28 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-200 hover:bg-red-500"
                >
                  Reset
                </button>
                <button
                  type="submit"
                  onClick={() => onSubmit()}
                  className="rounded-md bg-indigo-600 px-3 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Footer Start */}
      <footer className="w-full flex flex-wrap p-6 m-auto justify-center ">
        <div className="w-full md:w-1/5  flex flex-col items-start m-1 p-2">
          <a href="./Home">
            <img src={img} className="w-48 mb-4" />
          </a>
          <p className="text-sm">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content. Lorem ipsum may be
            used as a placeholder before the final copy is available.
          </p>
          <div className="flex mt-8">
            <a href="#">
              <FaFacebook className="text-2xl md:text-4xl mr-4 text-blue-800" />
            </a>
            <a href="#">
              <FaTwitter className="text-2xl md:text-4xl mr-4 text-indigo-900" />
            </a>
            <a href="#">
              <FaYoutube className="text-2xl md:text-4xl mr-4 text-red-600" />
            </a>
            <a href="#">
              <FaInstagram className="text-2xl md:text-4xl mr-4 text-pink-600" />
            </a>
          </div>
        </div>

        <div className="w-full md:w-1/5  m-1 mt-3 p-2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl font-bold">Quick Links</h2>
          <ul className="pt-4 text-lg space-y-2">
            <li>
              <a href="#" className="text-blue-700 font-bold">
                Home
              </a>
            </li>
            <li>
              <a href="./about" className="text-blue-700 font-bold">
                About Us
              </a>
            </li>
            <li>
              <a href="./Services" className="text-blue-700 font-bold">
                Services
              </a>
            </li>
            <li>
              <a href="./Gallery" className="text-blue-700 font-bold">
                Gallery
              </a>
            </li>
            <li>
              <a href="./contact" className="text-blue-700 font-bold">
                Contact Us
              </a>
            </li>
          </ul>
        </div>

        <div className="w-full md:w-1/5  m-1  mt-3 p-2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl font-bold">Services</h2>
          <ul className="pt-4 text-lg space-y-2">
            <li>
              <a href="#" className="text-blue-700 font-bold">
                GpgTracking
              </a>
            </li>
            <li>
              <a href="#" className="text-blue-700 font-bold">
                DataBackUp
              </a>
            </li>
            <li>
              <a href="#" className="text-blue-700 font-bold">
                Security Alert
              </a>
            </li>
            <li>
              <a href="#" className="text-blue-700 font-bold">
                24/7 Services
              </a>
            </li>
            <li>
              <a href="#" className="text-blue-700 font-bold">
                Mobile App Access
              </a>
            </li>
          </ul>
        </div>

        <div className="w-full md:w-1/5  m-1 p-2 text-center">
          <h2 className="text-2xl md:text-3xl font-bold pb-4">Reach Us</h2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.835086667974!2d85.83567077500946!3d20.257281081206035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a190a7255555555%3A0xb4da5c97fe15e672!2sEXPERT%20SOLUTIONS!5e1!3m2!1sen!2sin!4v1725531129388!5m2!1sen!2sin"
            style={{ border: 0 }}
            className="w-full h-64"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </footer>

      <div className="p-2 bg-slate-700 text-center">
        <p className="text-lg text-white">
          Copyright ©{" "}
          <b>
            <a href="#" className="text-white">
              Expert Solution
            </a>
          </b>{" "}
          2024. All Rights Reserved.
        </p>
      </div>
    </>
  );
}
