import React from "react";
import img from "./images/logo2.svg";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
export default function Footer() {
  return (
    <>
      <footer className="w-full flex flex-wrap p-6 m-auto justify-center ">
        <div className="w-full md:w-1/5  flex flex-col items-start m-1 p-2">
          <a href="./Home">
            <img src={img} className="w-48 h-20 mb-1" />
          </a>
          <p className="text-sm">
            At s-modality we have customized offerings for different domains.
            These solutions enable the users to manage and monitor their assets
            effectively and profitably, as per their requirements, thereby
            increasing the business efficiency.
          </p>
          <div className="flex mt-8">
            <a href="#">
              <FaFacebook className="text-2xl md:text-4xl mr-4 text-blue-800" />
            </a>
            <a href="#">
              <FaTwitter className="text-2xl md:text-4xl mr-4 text-indigo-900" />
            </a>
            <a href="#">
              <FaYoutube className="text-2xl md:text-4xl mr-4 text-red-600" />
            </a>
            <a href="#">
              <FaInstagram className="text-2xl md:text-4xl mr-4 text-pink-600" />
            </a>
          </div>
        </div>

        <div className="w-full md:w-1/5  m-1 mt-3 p-2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl font-bold">Quick Links</h2>
          <ul className="pt-4 text-lg space-y-2">
            <li>
              <a href="#" className="text-blue-700 font-bold">
                Home
              </a>
            </li>
            <li>
              <a href="./about" className="text-blue-700 font-bold">
                Company
              </a>
            </li>
            <li>
              <a href="./Services" className="text-blue-700 font-bold">
                Technology
              </a>
            </li>
            <li>
              <a href="./Gallery" className="text-blue-700 font-bold">
                Services
              </a>
            </li>
            <li>
              <a href="./contact" className="text-blue-700 font-bold">
                Industries
              </a>
            </li>
            <li>
              <a href="./contact" className="text-blue-700 font-bold">
                Solutions
              </a>
            </li>
            <li>
              <a href="./contact" className="text-blue-700 font-bold">
                Contact Us
              </a>
            </li>
          </ul>
        </div>

        <div className="w-full md:w-1/5  m-1  mt-3 p-2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl font-bold">Services</h2>
          <ul className="pt-4 text-lg space-y-2">
            <li>
              <a href="#" className="text-blue-700 font-bold">
                Ecommerce
              </a>
            </li>
            <li>
              <a href="#" className="text-blue-700 font-bold">
                MobileApp
              </a>
            </li>
            <li>
              <a href="#" className="text-blue-700 font-bold">
                UI/UX
              </a>
            </li>
            <li>
              <a href="#" className="text-blue-700 font-bold">
                Digital Marketing
              </a>
            </li>
            <li>
              <a href="#" className="text-blue-700 font-bold">
                Gps Supplier
              </a>
            </li>
            <li>
              <a href="#" className="text-blue-700 font-bold">
                Taxi services
              </a>
            </li>
          </ul>
        </div>

        <div className="w-full md:w-1/5  m-1 p-2 text-center">
          <h2 className="text-2xl md:text-3xl font-bold pb-4">Reach Us</h2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.835086667974!2d85.83567077500946!3d20.257281081206035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a190a7255555555%3A0xb4da5c97fe15e672!2sEXPERT%20SOLUTIONS!5e1!3m2!1sen!2sin!4v1725531129388!5m2!1sen!2sin"
            style={{ border: 0 }}
            className="w-full h-64"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </footer>

      <div className="p-2 bg-slate-700 text-center">
        <p className="text-lg text-white">
          Copyright ©{" "}
          <b>
            <a href="#" className="text-white">
              Expert Solution
            </a>
          </b>{" "}
          2024. All Rights Reserved.
        </p>
      </div>
    </>
  );
}
