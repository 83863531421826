import {useState} from "react";
// import { Facebook } from 'react-icons';
// import { IonIcon } from 'react-iicons';

import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/react";
import { Field, Label, Switch } from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import img from "./images/logo2.svg";
import banner from "./images/banner.jpg";
import serv1 from "./images/gps.png";
import serv2 from "./images/alert.jpg";
import serv3 from "./images/Backup.jpg";
import serv4 from "./images/facility.jpeg";
import about from "./images/aboutus.png";
import contact from "./images/contact.jpg";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import Header from './header'
import {
  FaArrowRight,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaMailBulk,
  FaMailchimp,
  FaSortDown,
  FaTwitter,
  FaVoicemail,
  FaYoutube,
} from "react-icons/fa";
import {
  FaLocationDot,
  FaMapLocation,
  FaPhoenixSquadron,
  FaPhone,
} from "react-icons/fa6";

// change
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {} from "@headlessui/react";
import Footer from "./footer";
// changeend

const products = [
  {
    name: "Analytics",
    description: "Get a better understanding of your traffic",
    href: "#",
    icon: ChartPieIcon,
  },
  {
    name: "Engagement",
    description: "Speak directly to your customers",
    href: "#",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Security",
    description: "Your customers’ data will be safe and secure",
    href: "#",
    icon: FingerPrintIcon,
  },
  {
    name: "Integrations",
    description: "Connect with third-party tools",
    href: "#",
    icon: SquaresPlusIcon,
  },
  {
    name: "Automations",
    description: "Build strategic funnels that will convert",
    href: "#",
    icon: ArrowPathIcon,
  },
];
const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "#", icon: PhoneIcon },
];

function Homepage() {
  
  const [agreed, setAgreed] = useState(false);
  // Replace with your logo path

  //change
  // Define the validation schema
  const validationSchema = Yup.object().shape({
    // firstName: Yup.string().required('First name is required'),
    firstName: Yup.string()
      .required("First name is required")
      .matches(/^[A-Za-z]+$/, "Only alphabets are allowed"),
    // lastName: Yup.string().required('Last name is required'),

    lastName: Yup.string()
      .required("last name is required")
      .matches(/^[A-Za-z]+$/, "Only alphabets are allowed"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, "Phone number is not valid")
      .required("Phone number is required"),
    // streetAddress: Yup.string().required('Street address is required'),

    Address: Yup.string()
      .required("Address is required")
      .matches(/^[A-Za-z]+$/, "Only alphabets are allowed"),

    agreed: Yup.boolean().oneOf([true], "You must accept the privacy policy"),
  });

  // Set up the form with validation resolver
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // Form submit handler
  const onSubmit = (data) => {
    console.log("Form Submitted:", data);
  };


  

  return (
    <>

      <Header />
      <div
        style={{
          backgroundImage: `url('${banner}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundBlendMode: "multiply",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust color and opacity as needed
        }}
        className="relative isolate h-96 md:h-[32rem] flex items-center justify-center overflow-hidden bg-cover bg-center w-full"
      >
        <div className="absolute inset-0 flex flex-col items-center text-center px-4 md:px-8 space-y-4 mt-24">
          <h1 className="text-3xl md:text-6xl font-mono font-bold text-white">
            Welcome To S-Modality
          </h1>
          <p className="text-sm md:text-base text-white max-w-2xl">
          At S-modality we have customized offerings for different domains. These solutions enable the users to manage and monitor their assets effectively and profitably, as per their requirements, thereby increasing the business efficiency.
          </p>
        </div>
      </div>

      {/* About US Start */}
      <div id="about_Us">
        <div>
          <h2 className="flex text-4xl font-mono font-bold text-black text-center m-4 justify-center">
            About Us
          </h2>
        </div>
        <div className="flex flex-col md:flex-row h-auto p-4 md:justify-center md:m-10">
          {/* Section for Text */}
          <div className="w-full md:w-2/5 p-2 pt-20 md:mr-2">
            <h3 className="text-2xl md:text-3xl text-red-600 font-bold pb-4">
              India Best Gps Tracking Services Company
            </h3>
            <p className="text-sm md:text-xl pb-4">
            All our solutions are Customized & designed to meet the specific needs and challenges of your business. Our software solutions help increase productivity, improve efficiency, reduce overall cost and enhance performance & competitiveness.

            <br/>
            We have an installed base of 1 Lac+ Vehicles , provide service across 150 locations , 24/7 Helpline, with complete integration to SAP/Oracle/ERP systems & multiple Vendor configurations .
            <br/>
            We specialize in offering innovative yet cost effective hardware and software solution. Our products are best in its class solutions for complete fleet management, Warehousing , Logistics ,Employee transportation & allied businesses.
            </p>
            <button className="text-white font-bold bg-sky-900 py-2 px-4 rounded-full">
              Read More
            </button>
          </div>

          {/* Section for Image */}
          <div className="w-full md:w-2/5 p-2 md:ml-2">
            <img src={about} alt="About Page Images" className="w-full" />
          </div>
        </div>
      </div>

      <div className="h-auto p-10 bg-slate-200 border" id="Services">
        <h3 className="flex text-4xl font-mono font-bold text-black justify-center items-center text-center border-green-300">
          Services
        </h3>
        <div className="flex flex-wrap mt-10 justify-center gap-4">
          {/* Service Card 1 */}
          <div className="h-auto w-full sm:w-60 md:w-72 p-4 bg-white rounded-xl shadow-xl shadow-cyan-500/50">
            <img
              src={serv1}
              alt="FirstService"
              className="h-60 w-full rounded-xl"
            />
            <h3 className="text-2xl md:text-3xl font-bold mt-4">
              Tracking Device
            </h3>
            <p className="text-sm md:text-base mt-2">
              Using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here.
            </p>
            <button className="text-white font-bold bg-sky-900 py-2 px-4 rounded-full mt-3">
              Read More
            </button>
          </div>

          {/* Service Card 2 */}
          <div className="h-auto w-full sm:w-60 md:w-72 p-4 bg-white rounded-xl shadow-xl shadow-cyan-500/50">
            <img
              src={serv2}
              alt="SecondService"
              className="h-60 w-full rounded-xl"
            />
            <h3 className="text-2xl md:text-3xl font-bold mt-4">
              Alert Service
            </h3>
            <p className="text-sm md:text-base mt-2">
              Using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here.
            </p>
            <button className="text-white font-bold bg-sky-900 py-2 px-4 rounded-full mt-3">
              Read More
            </button>
          </div>

          {/* Service Card 3 */}
          <div className="h-auto w-full sm:w-60 md:w-72 p-4 bg-white rounded-xl shadow-xl shadow-cyan-500/50">
            <img
              src={serv3}
              alt="ThirdService"
              className="h-60 w-full rounded-xl"
            />
            <h3 className="text-2xl md:text-3xl font-bold mt-4">Data Backup</h3>
            <p className="text-sm md:text-base mt-2">
              Using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here.
            </p>
            <button className="text-white font-bold bg-sky-900 py-2 px-4 rounded-full mt-3">
              Read More
            </button>
          </div>

          {/* Service Card 4 */}
          <div className="h-auto w-full sm:w-60 md:w-72 p-4 bg-white rounded-xl shadow-xl shadow-cyan-500/50">
            <img
              src={serv4}
              alt="FourthService"
              className="h-60 w-full rounded-xl"
            />
            <h3 className="text-2xl md:text-3xl font-bold mt-4">
              24/7 Services
            </h3>
            <p className="text-sm md:text-base mt-2">
              Using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here.
            </p>
            <button className="text-white font-bold bg-sky-900 py-2 px-4 rounded-full mt-3">
              Read More
            </button>
          </div>
        </div>
      </div>

      {/*Contact US Start  */}

      <div className="flex flex-col md:flex-row h-auto p-4 md:space-y-0 space-y-8 justify-center">
        {/* Get In Touch Section */}
        <div className="w-full md:w-2/5 h-auto p-2 pt-10 md:mr-2 justify-between">
          <div className="pb-6">
            <h2 className="font-bold text-2xl md:text-4xl tracking-wide">
              Get In Touch
            </h2>
            <p className="pt-8 text-base md:text-xl leading-6">
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content. Lorem ipsum may
              be used as a placeholder before the final copy is available.
            </p>
          </div>
          <div className="flex flex-col space-y-4">
            {/* Phone Section */}
            <div className="inline-flex space-x-2 items-center p-6 w-full md:w-96 bg-sky-900 rounded-md shadow-lg shadow-blue-500/50">
              <FaPhone
                className="text-2xl w-10 text-white"
                aria-label="Phone Icon"
              />
              <a
                href="tel:+916268916111"
                className="font-bold w-80 text-white hover:underline"
              >
                +91 6268916111
              </a>
            </div>

            {/* Email Section */}
            <div className="inline-flex space-x-2 items-center p-6 w-full md:w-96 bg-sky-900 rounded-md shadow-lg shadow-blue-500/50">
              <FaEnvelope
                className="text-3xl w-10 text-white"
                aria-label="Email Icon"
              />
              <a
                href="mailto:expgpsrpr@gmail.com"
                className="font-bold w-80 text-white hover:underline"
              >
                expgpsrpr@gmail.com
              </a>
            </div>

            {/* Location Section */}
            <div className="inline-flex space-x-2 items-center p-6 w-full md:w-96 bg-sky-900 rounded-md shadow-lg shadow-blue-500/50">
              <FaLocationDot
                className="text-3xl w-10 text-white"
                aria-label="Location Icon"
              />
              <a
                href="https://www.google.com/maps/search/?api=1&query=78+Gautam+Nagar+St,+Bapuji+Nagar,+Bhubaneswar,+Odisha+751014"
                target="_blank"
                rel="noopener noreferrer"
                className="font-bold w-80 text-white hover:underline"
              >
                78, Gautam Nagar St, Bapuji Nagar, Bhubaneswar, Odisha 751014
              </a>
            </div>
          </div>
        </div>

        {/* Contact Us Form Section */}
        <div className="w-full md:w-2/5 p-2 md:ml-2 pt-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-12">
              <div>
                <h1 className="leading-7 text-gray-900 text-2xl md:text-4xl font-bold">
                  Contact Us
                </h1>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      First name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="first-name"
                        {...register("firstName", {
                          required: "First name is required",
                          pattern: {
                            value: /^[A-Za-z]+$/, // Regex pattern for alphabetic characters only
                            message: "First name should only contain letters",
                          },
                        })}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.firstName && (
                        <p className="text-red-500 text-sm">
                          {errors.firstName.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Last name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="last-name"
                        {...register("lastName", {
                          required: "Last name is required",
                          pattern: {
                            value: /^[A-Za-z]+$/, // Regex pattern for alphabetic characters only
                            message: "Last name should only contain letters",
                          },
                        })}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.lastName && (
                        <p className="text-red-500 text-sm">
                          {errors.lastName.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-full">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        {...register("email")}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.email && (
                        <p className="text-red-500 text-sm">
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-full">
                    <label
                      htmlFor="phone-number"
                      className="block text-sm font-semibold leading-6 text-gray-900"
                    >
                      Phone number
                    </label>
                    <div className="relative mt-2.5">
                      {/* <div className="absolute inset-y-0 left-0 flex items-center">
                  <select
                    id="country"
                    name="country"
                    className="h-full rounded-md border-0 bg-transparent py-0 pl-4 pr-4 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                  >
                    <option>IND</option>
                    <option>USA</option>
                    <option>AUS</option>
                  </select>
                </div> */}
                      <input
                        type="phone"
                        id="phone-number"
                        {...register("phoneNumber")}
                        className="block w-full rounded-md border-0 px-4 py-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.phoneNumber && (
                        <p className="text-red-500 text-sm">
                          {errors.phoneNumber.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="Address"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Address
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="Address"
                        {...register("Address")}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.Address && (
                        <p className="text-red-500 text-sm">
                          {errors.Address.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-b border-gray-900/10 pb-12">
                <div className="flex gap-x-4 sm:col-span-2">
                  <div className="flex h-6 items-center">
                    <Controller
                      name="agreed"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Switch
                          checked={field.value}
                          onChange={field.onChange}
                          className="group flex w-8 flex-none cursor-pointer rounded-full bg-gray-200 p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 data-[checked]:bg-indigo-600"
                        >
                          <span
                            aria-hidden="true"
                            className="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out group-data-[checked]:translate-x-3.5"
                          />
                        </Switch>
                      )}
                    />
                  </div>
                  <label className="text-sm leading-6 text-gray-600">
                    By selecting this, you agree to our{" "}
                    <a href="#" className="font-semibold text-indigo-600">
                      privacy&nbsp;policy
                    </a>
                    .
                  </label>
                  {errors.agreed && (
                    <p className="text-red-500 text-sm">
                      {errors.agreed.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                  type="reset"
                  onClick={() => reset()}
                  className="text-md font-semibold leading-6  bg-red-600 text-white rounded-md px-3 py-2 w-28  shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-200 hover:bg-red-500"
                >
                  Reset
                </button>
                <button
                  type="submit"
                  onClick={() => onSubmit()}
                  className="rounded-md bg-indigo-600 px-3 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Homepage;
